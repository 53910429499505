.draft-wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}

.draft-editor-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.draft-toolbar-class {
  border: 1px solid #ccc;
}
