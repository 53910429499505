.recharts-rectangle.recharts-tooltip-cursor {
  fill: rgba(51, 153, 255, 0.1);
}
.ant-table {
  background: var(--white-color) !important;
  border-radius: 8px !important;
}

.ant-table-thead .ant-table-cell,
.ant-table-cell-row-hover {
  background-color: #f0f2f5 !important;
}

.ant-table-cell {
  padding: 10px 15px !important;
}

.overview-detail-application .ant-table-cell {
  padding: 10px !important;
  vertical-align: baseline;
}

.ant-back-top {
  right: 20px !important;
  bottom: 20px !important;
  border-radius: 8px !important;
  background: rgba(0, 51, 102, 0.2) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;
}

.ant-back-top:hover {
  background: var(--dark-blue) !important;
}

.ant-back-top svg {
  font-size: 20px !important;
  color: var(--white-color);
}

.ant-picker-time-panel-column::after {
  content: none !important;
}

.ant-layout-sider-zero-width-trigger {
  display: none;
}

.ant-layout-sider-zero-width-trigger::after {
  content: none !important;
}

.overview-line-chart-tooltip ul li {
  display: flex !important;
  font-style: var(--font-style);
  font-weight: var(--font-style);
  font-size: 10px;
  line-height: var(--line-height-text);
  color: var(--text-color);
}

.overview-line-chart-tooltip ul li:nth-child(1),
.overview-line-chart-tooltip ul li:nth-child(3) {
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
  letter-spacing: 0.4px;
  color: var(--header-color);
}

.overview-line-chart-tooltip ul li:nth-child(1) span:nth-child(3) {
  color: #f0d234;
}

.overview-line-chart-tooltip ul li:nth-child(3) span:nth-child(3) {
  color: var(--light-blue);
}

.overview-line-chart-tooltip ul li span:nth-child(3) {
  flex: 1;
  text-align: right;
}

.overview-bar-chart-tooltip ul li {
  display: flex !important;
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
  letter-spacing: 0.4px;
  color: var(--header-color);
}

.overview-bar-chart .recharts-tooltip-item-list li:nth-child(2),
.overview-bar-chart
  .recharts-tooltip-item-list
  li:nth-child(2)
  .recharts-tooltip-item-value {
  font-weight: var(--font-weight-text);
  color: var(--green) !important;
}

.overview-bar-chart-tooltip ul li span:nth-child(3) {
  color: var(--light-blue);
  flex: 1;
  text-align: right;
}

.overview-region-beside .recharts-default-legend {
  display: flex;
  flex-wrap: wrap;
}

/* .overview-region-beside .recharts-legend-wrapper {
  top: 234px !important;
} */

.overview-region-beside .recharts-default-legend li {
  flex-basis: 50%;
  margin: 10px 0 0 0 !important;
  text-align: left;
}

.ant-select-selector,
.ant-picker.ant-picker-range {
  background: #f0f2f5;
  border-radius: 4px;
  border: none;
}

.recharts-layer.recharts-bar-rectangle path {
  stroke-linecap: round;
}

.ant-collapse-header {
  display: none !important;
}

.overview-region-container .ant-empty {
  margin: 0px !important;
  height: 90% !important;
  background-color: var(--white-color);
  border-radius: 8px !important;
}

.overview-bar-chart-empty > h1 {
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: var(--font-size-header);
  line-height: var(--line-height-header);
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--header-color);
}

.overview-region-container .ant-empty-image,
.overview-region-container .ant-empty-description {
  transform: translateY(100px) !important;
}

.overview-region-container .ant-empty-description {
  font-size: 16px !important;
  font-weight: var(--font-weight-header);
}

.overview-detail-top-stream .ant-empty {
  height: 332px !important;
  margin: 0 !important;
}

.overview-detail-top-stream .ant-empty-image,
.overview-detail-top-stream .ant-empty-description {
  transform: translateY(60px) !important;
}

.overview-detail-top-stream .ant-empty-image {
  height: 164px !important;
}

.overview-detail-top-stream .ant-empty-description {
  font-size: 16px !important;
  font-weight: var(--font-weight-header);
}

.ant-modal-content {
  border-radius: 10px !important;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 10px;
}

.ant-modal-header {
  border-radius: 20px !important;
  border: none;
}

.ant-modal-title {
  font-weight: var(--font-weight-header) !important;
  font-size: var(--font-size-overview) !important;
  line-height: var(--line-height-overview) !important;
  letter-spacing: 0.18px !important;
  color: var(--header-color) !important;
  /* text-align: center; */
}

.ant-row-active {
  background-color: #f0f2f5;
}

.ant-modal-body p span {
  color: var(--dark-blue);
  font-weight: var(----font-weight-header);
}

.ant-modal-header {
  border-bottom: none !important;
}

.ant-modal-footer {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  border-top: none !important;
}

.ant-modal-footer button {
  margin-left: 0 !important;
  min-width: 80px;
}

/* antd collapse */
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 8px;
}

.ant-collapse > .ant-collapse-item {
  border-bottom: none;
}

/* ant button */
.ant-btn {
  border-radius: 4px !important;
}

.ant-btn-primary {
  border-color: var(--dark-blue) !important;
  background: var(--dark-blue) !important;
}

.ant-btn-outline {
  border-color: var(--dark-blue) !important;
  color: var(--dark-blue) !important;
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  opacity: 0.7;
}

/* antd message */
.share-rate-alert .ant-message-notice-content {
  cursor: pointer;
  padding: 0;
}

/* antd date picker */
.ant-picker-month-panel .ant-picker-cell .ant-picker-cell-inner {
  min-width: 64px;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: var(--dark-blue) !important;
}

.ant-picker-date-panel .ant-picker-cell-inner {
  border-radius: 50% !important;
  width: 36px !important;
  height: 36px !important;
  line-height: 36px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 50%;
  border-color: var(--dark-blue);
}
