ul {
  list-style: none;
  padding: 0;
}

.login-form--wrap {
  height: 100vh;
  display: flex;
  background: #e5e5e5;
  flex-direction: column;
}

.login-form--wrap > .login-container {
  flex: 1;
}

.login-form {
  width: 454px;
  min-height: 376px;
  background: var(--white-color);
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 24px;
  margin-top: 40px;
}

.form-title {
  text-align: center;
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: var(--font-size-overview);
  line-height: var(--line-height-overview);
  margin-bottom: 20px;
  color: var(--header-color);
}

.form-description {
  font-style: var(--font-style);
  font-weight: var(--font-style);
  font-size: var(--font-size-header);
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #7f8183;
}

.ant-form-item {
  margin: 24px 0 0 0 !important;
}

.ant-form-item.first-input-field {
  margin-top: 0 !important;
}

.ant-form-item.last-input-field {
  margin-bottom: 10px !important;
}

.ant-input-affix-wrapper-lg {
  padding: 16px !important;
  border-radius: 8px !important;
  border: 2px solid #d9dbdd !important;
  background: var(--white-color) !important;
}

.ant-input-prefix {
  margin-right: 0;
}
.ant-form-item:nth-child(1) .ant-input-prefix {
  margin-right: 10px !important;
}

.form-input-password-again .ant-input-prefix {
  margin-right: 12px !important;
}

.ant-form-item:nth-child(2) .ant-input-prefix {
  margin-right: 12px !important;
}

.ant-input {
  font-size: var(--font-size-header) !important;
  line-height: 20px !important;
  letter-spacing: 0.25px !important;
  color: var(--header-color) !important;
  font-style: var(--font-style) !important;
  font-weight: var(--font-style) !important;
}

.ant-switch {
  min-width: 32px !important;
  height: 16px !important;
  margin-right: 8px !important;
}

.login-remember {
  font-style: var(--font-style);
  font-weight: var(--font-style);
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
  letter-spacing: 0.4px;
  color: #2d3748;
}

.ant-switch-handle {
  top: 2px !important;
  left: 2px !important;
  width: 12px !important;
  height: 12px !important;
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 14px) !important;
}

.login-button {
  height: 56px !important;
  border-radius: 8px !important;
  background: var(--dark-blue) !important;
}

.login-button:hover {
  background: rgba(0, 51, 102, 0.8) !important;
}

.login-button a {
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: var(--font-size-header);
  line-height: var(--line-height-text);
  color: #ffffff;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.form-forgot-password {
  text-align: center;
  margin-top: 24px;
}

.form-forgot-password a {
  font-style: var(--font-style);
  font-weight: var(--font-style);
  font-size: var(--font-size-header);
  line-height: var(--line-height-text);
  letter-spacing: 1.25px;
  color: var(--light-blue);
}
.form-input .ant-input {
  background-color: var(--white-color);
}

.form-input:hover,
.form-input:focus {
  border-color: var(--dark-blue) !important;
}

.ant-switch-checked {
  background-color: #1890ff !important;
}

.form-resend-link {
  margin-top: 20px;
  margin-bottom: 120px;
}

.form-resend-link span:nth-child(1) {
  font-style: var(--font-style);
  font-weight: var(--font-style);
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
  letter-spacing: 0.4px;
  color: var(--header-color);
  margin-right: 8px;
}

.form-resend-link span:nth-child(2) {
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
  letter-spacing: 0.4px;
  color: var(--light-blue);
  cursor: pointer;
}

.form-cancel-to-login {
  font-style: var(--font-style);
  font-weight: var(--font-style);
  font-size: var(--font-size-header);
  line-height: var(--line-height-text);
  letter-spacing: 1.25px;
  color: var(--dark-blue);
  text-transform: uppercase;
  text-align: center;
  margin-top: 24px;
}

/* .form-password-advice-icon{
    margin-right: 9.33px !important;
} */

.form-password-advice span {
  margin-left: 9.33px;
  display: inline-block;
  font-style: var(--font-style);
  font-weight: var(--font-style);
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);

  letter-spacing: 0.4px;

  color: #87898e;
}

.form-password-advice {
  width: 100%;
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.form-success-icon {
  margin-top: 48.33px;
  display: flex;
  justify-content: center;
}

.form-success-icon-beside {
  height: 83.33px;
  width: 83.33px;
  background-color: var(--light-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.form-password-change-successfully {
  font-style: var(--font-style);
  font-weight: var(--font-style);
  font-size: var(--font-size-header);
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #7f8183;
  margin-top: 32.33px;
  margin-bottom: 40px;
}

.login-container {
  height: 100vh;
  width: 100%;
  background-image: url("../assets/images//login//login-img.png");
  /* background-repeat: no-repeat; */
  background-color: var(--dark-blue);
  background-size: 100% 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.login-container > img {
  position: absolute;
  top: 40px;
}

.footer {
  text-align: center;
  font-style: var(--font-style);
  font-weight: var(--font-style);
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
  letter-spacing: 0.4px;
  color: #a5a8aa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-icon {
  /* display: inline-block; */
  margin-top: 4px;
  margin-right: 4px;
}

/*--------------------SCROLL BAR---------------------*/

/* ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 39.8054px;
}

::-webkit-scrollbar-thumb {
  background: #a5a8aa;
  border-radius: 39.8054px;
} */

.ant-col .ant-select-multiple {
  width: 100% !important;
}

.min-width-300 {
  min-width: 300px;
}
.min-width-280 {
  min-width: 280px;
}
.min-width-260 {
  min-width: 260px;
}
.min-width-240 {
  min-width: 240px;
}
.min-width-200 {
  min-width: 200px;
}
.min-width-180 {
  min-width: 180px;
}
.min-width-170 {
  min-width: 170px;
}
.min-width-160 {
  min-width: 160px;
}
.min-width-150 {
  min-width: 150px;
}
.min-width-140 {
  min-width: 140px;
}
.min-width-130 {
  min-width: 130px;
}
.min-width-120 {
  min-width: 120px;
}
.min-width-110 {
  min-width: 110px;
}
.min-width-100 {
  min-width: 100px;
}