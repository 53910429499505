.overview-bar {
  padding: 0px 24px;
  line-height: 80px;
  background-color: var(--white-color);
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.05);
}

.overview-bar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overview-bar-container-text {
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: var(--font-size-overview);
  line-height: var(--line-height-overview);
  letter-spacing: 0.18px;
  color: var(--header-color);
}

.overview-bar-filter ul li {
  font-style: var(--font-style);
  font-weight: var(--font-weight-text);
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
  letter-spacing: 0.4px;
  border-radius: 4px;
  color: var(--text-color);
  padding: 4px 17px;
  margin: 4px;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.overview-bar-filter ul li:hover,
.overview-bar-filter ul li.active {
  background: var(--white-color);
}

.overview-bar-filter span {
  margin-right: 8px;
  letter-spacing: 0.4px;
  color: var(--text-color);
  font-style: var(--font-style);
  font-weight: var(--font-weight-text);
  line-height: var(--line-height-text);
}

.overview-bar-filter ul {
  background: #f0f2f5;
  border-radius: 4px;
}

.overview-bar-main {
  display: flex;
}

.overview-bar-year {
  display: flex;
  align-items: center;
}

.overview-bar-filter {
  display: flex;
  align-items: center;
}

.overview-bar-filter ul {
  display: flex;
}

.overview-bar-filter ul {
  margin: 0;
}

.overview-bar-year-icon {
  line-height: 10px;
  margin-left: 23px;
  margin-right: 11px;
}

.overview-bar-year-main .ant-picker {
  border-radius: 4px;
}

.overview-bar-link button {
  color: #003366;
  border-color: #003366;
  font-weight: 500;
  border-radius: 4px;
  margin-left: 20px;
  text-transform: uppercase;
}

.overview-bar-link button:hover,
.overview-bar-link button.active {
  background: var(--dark-blue);
  color: var(--white-color);
  border-color: var(--dark-blue);
}

/*---------------Over-View-Total-----------------------*/

.overview-total-container:nth-child(1) {
  padding: 20px 10px 20px 20px !important;
}

.overview-total-container:nth-child(2) {
  padding: 20px 10px 20px 20px !important;
}

.overview-total-container:nth-child(3) {
  padding: 20px 10px 20px 20px !important;
}

.overview-total-item {
  padding: 12px 20px !important;
  background-color: var(--white-color);
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  position: relative;
}

.overview-total-item svg {
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom-right-radius: 8px;
}

.overview-total-item svg:nth-child(3) {
  bottom: 8.5px;
  right: 70px;
}

.overview-total-item svg:nth-child(4) {
  bottom: 52.5px;
  right: 37px;
}

.overview-total-item-beside h1 {
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: var(--font-size-header);
  line-height: var(--line-height-header);
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--header-color);
  margin-bottom: 8px;
}

.overview-total-item-beside p {
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: 34px;
  line-height: 36px;
  color: var(--light-blue);
  margin-bottom: 8px;
}

.overview-total-item-beside span:nth-child(3) {
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: var(--font-size-header);
  line-height: var(--line-height-header);
  letter-spacing: 0.1px;
  color: var(--green);
  margin-right: 8px;
}

.overview-total-item-beside span:nth-child(4) {
  font-style: var(--font-style);
  font-weight: normal;
  font-size: var(--font-size-header);
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #a5a8aa;
}

.overview-line-chart-container {
  padding-left: 20px;
  padding-right: 10px;
  height: 100%;
}

.overview-line-chart-beside {
  background-color: var(--white-color);
  padding: 20px 5px 20px 20px;
  border-radius: 8px;
  position: relative;
  height: 100%;
}

.overview-line-chart-beside h1 {
  position: absolute;
  top: 20px;
  left: 20px;
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: var(--font-size-header);
  line-height: var(--line-height-header);
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--header-color);
}

.overview-line-chart-beside p {
  position: absolute;
  top: 20px;
  right: 20px;
  font-style: var(--font-style);
  font-weight: normal;
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
  letter-spacing: 0.4px;
  color: var(--header-color);
}

.overview-line-chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overview-platform-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.overview-platform-title h1 {
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: var(--font-size-header);
  line-height: var(--line-height-header);
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--header-color);
  margin: 0 4px 0 0;
}

.overview-platform-title-sort {
  display: flex;
  align-items: center;
}

.overview-platform-sort-container {
  display: flex;
  gap: 10px;
}

.overview-platform-title-sort-text {
  font-style: var(--font-style);
  font-weight: var(--font-weight-text);
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
  letter-spacing: 0.4px;
  color: var(--text-color);
  margin-right: 12px;
}

.overview-platform-title-sort a {
  display: inline-block;
  background: #f0f2f5;
  border-radius: 4px;
  padding: 8px 12px;
  color: var(--text-color);
  font-style: var(--font-style);
  font-weight: var(--font-weight-text);
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
  letter-spacing: 0.4px;
}

.overview-platform-title-sort a span {
  margin-left: 5px;
  margin-right: 0px;
}

.platform-cell span:nth-child(2) {
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.platform-cell svg {
  margin-right: 5px;
  color: #ff0302;
  font-size: 20px;
}

.ant-table-row.ant-table-row-level-0 .platform-revenue {
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: 12px;
  line-height: var(--line-height-text);
  color: var(--green);
}

.ant-table-row.ant-table-row-level-0 .platform-revenue.active {
  color: #ea3d2f;
}

.ant-table-row.ant-table-row-level-0 .total-stream {
  font-style: var(--font-style);
  font-weight: normal;
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
  letter-spacing: 0.4px;
  color: var(--header-color);
}

.overview-bar-chart {
  padding: 10px;
  height: 100%;
}

.overview-bar-chart-beside {
  background-color: var(--white-color);
  padding: 20px 5px 20px 20px;
  border-radius: 8px;
  height: 100%;
  position: relative;
}

.overview-bar-chart-beside h1 {
  position: absolute;
  top: 20px;
  left: 20px;
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: var(--font-size-header);
  line-height: var(--line-height-header);
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--header-color);
}

.overview-bar-chart-beside p {
  position: absolute;
  top: 20px;
  right: 20px;
  font-style: var(--font-style);
  font-weight: normal;
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
  letter-spacing: 0.4px;
  color: var(--header-color);
}

.overview-region-container {
  padding: 10px;
}

.overview-detail-top-stream-beside .recharts-wrapper {
  width: auto !important;
}

.overview-bar-chart-empty {
  background-color: var(--white-color);
  padding: 20px 5px 20px 20px;
  border-radius: 8px;
  height: 100%;
}

.overview-bar-chart-container {
  height: 100%;
}

.overview-bar-chart-empty .ant-empty-description {
  font-size: 16px !important;
  font-weight: var(--font-weight-header);
}

.overview-bar-chart-empty .ant-empty {
  transform: translateY(77%);
}

.overview-detail-top-release {
  padding: 10px;
}

.overview-detail-top-release-container {
  background-color: var(--white-color);
  padding: 20px;
  border-radius: 8px;
}

/*----------------------------Top Song---------------------------*/

.ant-table-thead .overview-top-song-product,
.ant-table-thead .overview-top-song-time,
.ant-table-thead .overview-top-song-icon,
.ant-table-thead .overview-top-song-revenue,
.ant-table-thead .overview-top-song-streams,
.ant-table-thead .overview-top-song-top {
  font-style: var(--font-style);
  font-weight: normal;
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
  letter-spacing: 0.4px;
  color: #7f8183;
}

.ant-table-tbody .overview-top-song-product {
  display: flex;
  align-items: center;
}

.overview-top-song-product img {
  width: 36px;
  height: 36px;
}

.overview-top-song-product-content {
  margin-left: 5px;
}

.overview-top-song-product-content h1 {
  font-style: var(--font-style);
  font-weight: var(--font-weight-text);
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
  letter-spacing: 0.4px;
  color: var(--header-color);
  margin: 0;
}

.overview-top-song-product-content span {
  font-style: var(--font-style);
  font-weight: normal;
  font-size: 12px;
  line-height: var(--line-height-text);
  color: #7f8183;
  display: block;
  float: left;
}

.overview-top-song-time {
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
  letter-spacing: 0.4px;
  color: var(--header-color);
}

.overview-top-song-icon svg {
  margin: 0 8px;
}

.overview-top-song-icon svg:nth-child(1) {
  color: #ff0302;
  font-size: 23px;
}

.overview-top-song-icon svg:nth-child(2) {
  color: #1ed760;
  font-size: 20px;
}

.overview-top-song-icon svg:nth-child(3) {
  color: #ff0302;
  font-size: 20px;
}

.overview-top-song-streams {
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
  letter-spacing: 0.4px;
  color: var(--header-color);
}

.overview-top-song-revenue {
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
  letter-spacing: 0.4px;
  color: #2ec957;
}

.ant-menu {
  background-color: var(--dark-blue) !important;
  padding: 10px 0 !important;
}

.ant-menu li {
  margin: 0px !important;
  padding: 0px !important;
  margin-bottom: 10px !important;
}

.ant-menu li::after {
  border-right: none !important;
}

.ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-menu-item .ant-menu-title-content a svg,
.ant-menu-item .ant-menu-title-content a span {
  color: var(--white-color) !important;
  fill: var(--white-color) !important;
}

.ant-menu-item .ant-menu-title-content a.active {
  background-color: var(--white-color);
}

.ant-menu-item .ant-menu-title-content a.active svg,
.ant-menu-item .ant-menu-title-content a.active span {
  color: var(--dark-blue) !important;
  fill: var(--dark-blue) !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .ant-menu-title-content svg {
  fill: var(--dark-blue);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .ant-menu-title-content span {
  color: var(--dark-blue);
}

.ant-menu-title-content a {
  display: flex;
  align-items: center;
  padding-left: 6px;
}

.ant-menu-title-content a svg {
  fill: var(--white-color);
  margin: 0 10px;
}

.ant-menu-title-content a span,
.ant-menu-submenu-title span {
  letter-spacing: 0.1px;
  font-size: 14px;
  font-weight: 400;
  color: var(--white-color);
}

.ant-menu-submenu-title:active {
  background-color: transparent !important;
}

.ant-menu-submenu-title,
.ant-menu-submenu-title i {
  color: var(--white-color) !important;
}

.ant-menu-inline {
  border-right: none !important;
}

.ant-menu-submenu-title {
  padding: 0 !important;
  padding-left: 16px !important;
  margin: 0 !important;
}

.ant-menu-submenu-title .anticon {
  font-size: 20px !important;
}

.ant-menu-item:active {
  background: transparent !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item {
  transition: none !important;
}

.ant-menu-submenu ul {
  padding: 0 0 0 40px !important;
}

.ant-menu-title-content a span:last-child {
  margin-left: 0 !important;
}

.ant-menu-title-content svg {
  font-size: 20px !important;
}

.ant-layout-sider .ant-menu-sub {
  padding-left: 0 !important;
}

.ant-layout-sider .ant-menu-sub .ant-menu-item .ant-menu-title-content a {
  padding-left: 46px !important;
}

thead .ant-checkbox-inner::after {
  top: 7px !important;
  left: 3px !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  top: 7px !important;
  left: 7px !important;
}

/* styles filter buttons */
.react-date-range-container .rdrDateDisplayItem input,
.react-date-range-container .rdrMonthName {
  text-transform: capitalize !important;
}

.share-rate-message .ant-message-warning span:nth-child(1) svg {
  display: none;
}

.share-rate-message .ant-message-warning p {
  margin-top: 10px;
}

.share-rate-message .ant-message-warning span {
  text-align: center !important;
}

.share-rate-message .ant-message-notice-content {
  border-radius: 20px !important;
  /* padding: 20px !important; */
}

.share-rate-message .share-rate-header {
  color: red !important;
  font-size: 1rem;
  line-height: 22px;
}

.share-rate-message button {
  color: #fdfdfd !important;
  background-color: #003366 !important;
  border-color: #003366 !important;
  font-weight: 500;
  border-radius: 4px;
}

@media (max-width: 1281px) {
  .overview-total-item-beside p {
    font-size: 30px;
  }
}

@media (max-width: 980px) {

  .ant-menu-title-content a span,
  .ant-menu-submenu-title span {
    font-size: 12px;
  }
  .ant-menu-title-content a {
    padding-left: 0;
  }
  .ant-menu-submenu-title {
    padding-left: 10px !important;
  }
  .ant-layout-sider .ant-menu-sub .ant-menu-item .ant-menu-title-content a {
    padding-left: 40px !important;
  }
}