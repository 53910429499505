@import "~antd/dist/antd.css";

body {
  margin: 0;
  font-family: "Lexend", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  /*Color*/
  --white-color: #fdfdfd;
  --light-blue: #3399ff;
  --dark-blue: #003366;
  --green: #48bb78;
  --text-color: #3d3e3f;
  --header-color: #1e1e1f;

  /*Font-Size*/
  --font-size-header: 14px;
  --font-size-text: 13px;
  --font-size-overview: 18px;

  /*Font-Weight*/
  --font-weight-text: 400;
  --font-weight-header: 500;

  /*Font-style*/
  --font-style: normal;

  /*Line-Height*/
  --line-height-header: 24px;
  --line-height-text: 1.5;
  --line-height-overview: 31px;
}

p {
  margin-bottom: 10px;
}

.ant-modal-body {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem;
}

.ant-layout-content {
  background-color: #fff;
}

.ant-layout-sider {
  flex: none !important;
  max-width: 100% !important;
}

.ant-collapse {
  border: none !important;
}

.ant-collapse-content {
  border-top: none !important;
}

.full {
  width: 100%;
  height: 100%;
}
.pd-unset {
  padding: unset !important;
}
.mg-unset {
  margin: unset !important;
}

.flex-column {
  display: flex !important;
  flex-direction: column;
}
.font-bold {
  font-weight: 500;
}

.text-center {
  text-align: center !important;
}

.table-text-left {
  text-align: left;
}

.table-text-right {
  text-align: right;
}

.table-text-center {
  text-align: center;
}

.menu-item-text {
  font-size: 13px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

.fs-13px {
  font-size: 13px;
}

.fs-16px {
  font-size: 16px;
}

.fs-12px {
  font-size: 12px;
}

.fs-14px {
  font-size: 14px;
}

.op-5 {
  opacity: 0.5;
}

.mr-24px {
  margin-right: 24px;
}
.mr-5px {
  margin-right: 5px;
}

.mt-8px {
  margin-top: 8px;
}

.mt-24px {
  margin-top: 24px;
}

.mt-16px {
  margin-top: 16px;
}

.mb-15px {
  margin-bottom: 15px;
}

.mt-4px {
  margin-top: 4px;
}

.pd-20px {
  padding: 20px;
}
.c-t-label {
  color: #595959;
}

/* buttons */
.btn {
  cursor: pointer;
  min-width: 100px;
  border-radius: 4px;
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: var(--font-size-header);
  font-weight: var(--font-weight-header);
}

.small-btn {
  min-width: 80px;
  height: 30px;
  padding: 0;
  font-size: var(--font-size-text);
}

.primary-btn {
  color: var(--white-color);
  background: var(--dark-blue);
  border: 1px solid var(--dark-blue);
}

.outline-btn {
  color: var(--dark-blue);
  background: var(--white-color);
  border: 1px solid var(--dark-blue);
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 1.5rem;
}

.action-buttons button {
  min-width: 80px;
}

/* end of buttons styling */

/* style filters */
.filter-wrap {
  background: #e5e5e5;
  border-radius: 8px;
}

.filter-wrap .ant-collapse-content {
  margin: 20px;
  margin-bottom: 0;
}

/* end of styling filters */

.ant-table-thead .ant-table-cell {
  font-weight: 500;
  font-size: 14px !important;
  color: #1f1f1f !important;
  background-color: #f0f2f5 !important;
}

.table-title {
  background: #ffffff;
  box-shadow: 0px -3px 20px rgba(0, 0, 0, 0.25);
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  color: #1f1f1f;
  font-size: 13px;
  font-weight: 500;
}

.ant-table-body {
  overflow: auto !important;
}

.ant-table-thead .ant-table-cell {
  text-align: center;
}

.ant-table-pagination {
  margin-right: 10px !important;
}

.modal-content {
  width: 100%;
  height: calc(100vh - 96px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow-y: auto;
}

.popup-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: #fff;
}

.modal-header {
  font-size: 16px;
}
.modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px 2px 20px -2px rgba(0, 0, 0, 0.25);
}

/* form validate */
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  margin-left: 4px;
}
/* end form validate */

.ant-form-item-control-input-content .ant-picker {
  width: 100%;
}

.ant-input-number {
  width: 100% !important;
}

/* background: yellow button  */
.btn-bg-yellow {
  background-color: #ffd646 !important;
  border: none !important;
  color: #000000 !important;
}

.btn-bg-yellow:hover {
  color: #ffffff !important;
}

.open-sider-bar .submenu-content-title {
  padding-left: 48px !important;
}

input,
textarea,
.ant-select-selector .ant-select-selection-item {
  font-weight: 500;
}

.modal-image .ant-modal-close-x {
  display: none;
}

.color_module_m01 {
  color: #279bef;
}
.color_module_m02 {
  color: #d92026;
}
.color_module_m03 {
  color: #513b90;
}
.color_module_m04 {
  color: #ed6325;
}
.color_module_m05 {
  color: #23c16b;
}
.color_module_m06 {
  color: #e6649c;
}
.color_module_m07 {
  color: #e6649c;
}
.color_module_m08 {
  color: #23c16b;
}
.color_module_m09 {
  color: #ed6325;
}
.color_module_m10 {
  color: #513b90;
}
.color_module_m11 {
  color: #d92026;
}
.color_module_m12 {
  color: #279bef;
}

.ant-modal-confirm-btns {
  display: flex;
  flex-direction: row-reverse;
}

.ant-modal-confirm-btns button:last-child {
  margin-left: 0;
  margin-right: 8px;
}

.modal-delete .ant-modal-confirm-btns button:last-child {
  background: #ff5247;
  color: #ffffff;
  border: 1px solid #ff5247;
}

.modal-delete .ant-modal-confirm-btns button:last-child:hover,
.modal-cancel .ant-modal-confirm-btns button:last-child:hover {
  color: #000000;
}

.modal-cancel .ant-modal-confirm-btns button:last-child {
  background: #f8aa18;
  color: #ffffff;
  border: 1px solid #f8aa18;
}

.list-function .ant-menu-item {
  margin-bottom: 0 !important;
}

.list-function .ant-menu {
  border: unset;
}

.list-function .ant-menu-item,
.list-function
  .ant-menu-submenu.ant-menu-submenu-vertical
  div.ant-menu-submenu-title {
  padding: 0 60px 0 16px !important;
}

.flex {
  display: flex;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  /* border-radius: 39.8054px; */
  background: rgb(229, 229, 229);
}

::-webkit-scrollbar-thumb {
  background: #a5a8aa;
  border-radius: 39.8054px;
}

/* block of table data */
.table-data--wrap {
  padding: 20px;
  background: rgb(229, 229, 229);
}

.table-data--wrap .table-data--main {
  background-color: #fdfdfd;
  border-radius: 8px;
  position: relative;
}

.table-data--main .header {
  box-shadow: inset 0px -0.5px 0px #d9dbdd;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-data--main .header .title {
  font-weight: 500;
  font-size: 14px;
  align-self: center;
  text-transform: uppercase;
}

.table-data--main .header .button-payment {
  color: #fdfdfd !important;
  background-color: #003366 !important;
  border-color: #003366 !important;
  font-weight: 500;
  border-radius: 4px;
  transition: all 0.3s ease-out;
}

.ellipsis-options {
  text-overflow: ellipsis ;
  white-space: nowrap ;
  overflow: hidden ;
}

.row-khonhacso{
  height: 60px;
}