.overview-total {
  height: 100%;
  padding: 10px;
}

.overview-total-item {
  height: 100%;
}

.overview-detail-revenue {
  height: 100%;
  padding: 10px !important;
}

.overview-detail-top-stream-container-empty,
.overview-detail-top-artist-container-empty,
.overview-region-container-empty {
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  height: 100%;
}

.overview-detail-top-stream-beside .recharts-legend-wrapper,
.overview-detail-top-artist-beside .recharts-legend-wrapper,
.overview-region-beside .recharts-legend-wrapper {
  /* position: unset !important; */
  /* width: auto !important; */
  padding: 0 !important;
  bottom: -30px !important;
}

.overview-detail-application .ant-table-tbody {
  height: 529px !important;
}

.overview-detail-application-beside {
  height: 100%;
}

.overview-detail-top-stream-container-empty>h1,
.overview-detail-top-artist-container-empty>h1,
.overview-region-container-empty>h1 {
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: var(--font-size-header);
  line-height: var(--line-height-header);
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--header-color);
}

.overview-detail-revenue-item {
  height: 100%;
  padding: 12px 20px;
  background-color: var(--white-color);
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.overview-detail-revenue-item-beside h1 {
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: var(--font-size-header);
  line-height: var(--line-height-header);
  display: flex;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--header-color);
}

.overview-detail-revenue-item-body {
  padding: 12px;
  border-radius: 8px;
}

.overview-detail-revenue-3s {
  background: var(--light-blue);
  margin-bottom: 12px;
}

.overview-detail-revenue-artist {
  background: var(--dark-blue);
}

.overview-detail-revenue-item-body-beside {
  display: flex;
  align-items: center;
}

.overview-detail-revenue-item-body-beside h2 {
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: 20px;
  line-height: var(--line-height-header);
  letter-spacing: 0.15px;
  color: var(--white-color);
  margin: 0;
}

.overview-detail-revenue-item-body-mid {
  flex: 1;
  text-align: center;
}

.overview-detail-revenue-item-body-side {
  text-align: center;
}

.overview-detail-revenue-item-body-mid p,
.overview-detail-revenue-item-body-side p {
  margin: 0;
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: 20px;
  line-height: var(--line-height-header);
  letter-spacing: 0.15px;
  color: var(--white-color);
}

.overview-detail-revenue-item-body-mid span,
.overview-detail-revenue-item-body-side span {
  font-style: var(--font-style);
  font-weight: var(--font-weight-text);
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
  letter-spacing: 0.4px;
  color: rgba(253, 253, 253, 0.8);
}

.overview-detail-application,
.overview-detail-top-stream {
  padding: 10px;
  height: 420px;
}

.overview-detail-top-artist {
  padding: 10px;
  height: 388px;
}

.overview-region {
  padding: 10px;
  height: 400px;
}

.overview-detail-top-stream-container,
.overview-detail-top-artist-container,
.overview-region-container {
  background: #ffffff;
  border-radius: 8px;
  padding: 12px 20px;
  height: 100%;
}

.overview-detail-top-stream-container h1,
.overview-detail-top-artist-container h1,
.overview-region-container h1 {
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: var(--font-size-header);
  line-height: var(--line-height-header);
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--header-color);
}

.overview-detail-top-stream-container .recharts-wrapper ul,
.overview-detail-top-artist-container .recharts-wrapper ul,
.overview-region-container .recharts-wrapper ul {
  margin-bottom: 0 ;
}

.overview-detail-top-stream-container .recharts-wrapper li,
.overview-detail-top-artist-container .recharts-wrapper li,
.overview-region-container .recharts-wrapper li {
  padding: 5px 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
}

.ant-empty-description {
  font-size: 16px !important;
  font-weight: var(--font-weight-header) !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-table-thead .overview-detail-top-release-number,
.ant-table-thead .overview-detail-top-release-song,
.ant-table-thead .overview-detail-top-release-artist,
.ant-table-thead .overview-detail-top-release-album,
.ant-table-thead .overview-detail-top-release-time,
.ant-table-thead .overview-detail-top-release-stream,
.ant-table-thead .overview-detail-top-release-streamPer,
.ant-table-thead .overview-detail-top-release-listeners,
.ant-table-thead .overview-detail-top-release-perUser {
  font-style: var(--font-style);
  font-weight: var(--font-weight-text);
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
  letter-spacing: 0.4px;
  color: #000000;
}

.ant-table-tbody .overview-detail-top-release-song {
  display: flex;
  align-items: center;
}

.overview-detail-top-release-song h1 {
  font-style: var(--font-style);
  font-weight: var(--font-weight-text);
  font-size: var(--font-size-text);

  /* line-height: auto; */
  letter-spacing: 0.4px;
  color: var(--text-color);
  margin-left: 10px;
  margin-bottom: 0 !important;
  text-align: left;
}

.overview-detail-top-release-artist,
.overview-detail-top-release-album {
  font-style: var(--font-style);
  font-weight: var(--font-weight-text);
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);

  letter-spacing: 0.4px;

  color: var(--text-color);
}

.overview-detail-top-release-time,
.overview-detail-top-release-stream,
.overview-detail-top-release-streamPer,
.overview-detail-top-release-listeners,
.overview-detail-top-release-perUser {
  font-style: var(--font-style);
  font-weight: var(--font-weight-text);
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
  letter-spacing: 0.4px;
  color: var(--header-color);
}

.overview-detail-bar {
  padding: 0px 25px;
  height: 50px;
  display: flex;
  background-color: var(--white-color);
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.05);
}

.overview-detail-bar-container-header {
  position: relative;
  flex: 1;
}

.overview-detail-bar-container-header div {
  position: absolute;
  line-height: 20px;
  bottom: -27px;
}

.overview-detail-bar-container-header div svg {
  margin-right: 8px;
}

.overview-detail-bar-container-header span:nth-child(2) {
  position: absolute;
  bottom: 0;
}

.overview-detail-bar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.overview-detail-bar-container-text,
.overview-detail-bar-container-header span:nth-child(1) {
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: var(--font-size-overview);
  line-height: var(--line-height-overview);
  letter-spacing: 0.18px;
  color: var(--header-color);
}

.overview-detail-bar-main {
  display: flex;
}

.overview-detail-bar-year {
  display: flex;
  align-items: center;
}

.overview-detail-bar-link button {
  color: #003366;
  border-color: #003366;
  font-weight: 500;
  border-radius: 4px;
}

.overview-detail-bar-link button:hover {
  color: #fdfdfd;
  background-color: #003366;
  border-color: #003366;
}

.overview-detail-bar-year-icon {
  line-height: 10px;
  margin: 0 23px;
  cursor: pointer;
}

.overview-detail-bar-filter-icon {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.overview-detail-bar-filter-icon.active span {
  background-color: #003366;
  border-radius: 4px;
  padding: 8px;
}

.overview-detail-bar-filter-icon.active svg {
  color: #fdfdfd;
}

.overview-detail-bar-filter-icon span {
  line-height: 10px !important;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px;
}

.overview-detail-bar-year-main {
  margin-right: 20px;
}

.overview-detail-filter {
  padding: 20px;
  background-color: var(--white-color);
}

.overview-detail-filter h1 {
  font-style: var(--font-style);
  font-weight: var(--font-weight-header);
  font-size: var(--font-size-header);
  line-height: var(--line-height-header);
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--header-color);
  margin-bottom: 0 !important;
}

.overview-detail-filter-body {
  margin-top: 30px;
  /* text-align: center; */
}

.overview-detail-filter-body-item {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overview-detail-filter-body-item-title {
  width: 60px;
  text-align: right;
  margin-right: 10px;
  font-style: var(--font-style);
  font-weight: var(--font-weight-text);
  font-size: var(--font-size-header);
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #7f8183;
}

.overview-detail-album {
  padding: 20px 10px 20px 20px;
}

.overview-detail-album-container {
  background-color: var(--white-color);
  padding: 12px;
  border-radius: 8px;
  display: flex;
  height: 123.94px;
  align-items: center;
}

.overview-detail-album-container h1 {
  font-style: var(--font-style);
  font-weight: var(--font-weight-text);
  font-size: 20px;
  line-height: var(--line-height-header);
  letter-spacing: 0.15px;
  color: var(--header-color);
  margin-bottom: 0 !important;
}

.overview-detail-album-container img {
  width: 98px;
  height: 98px;
}

.overview-detail-album-body {
  margin-left: 12px;
}

.overview-detail-album-body span:nth-child(1) {
  font-style: var(--font-style);
  font-weight: var(--font-weight-text);
  font-size: var(--font-size-header);
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--text-color);
  margin-right: 8px;
}

.overview-detail-album-body span:nth-child(2) {
  font-style: var(--font-style);
  font-weight: var(--font-weight-text);
  font-size: var(--font-size-header);
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--light-blue);
}

.overview-detail-album-total:nth-child(1) {
  padding: 20px 20px 20px 10px;
}

.overview-detail-album-total:nth-child(2) {
  padding: 0 20px 20px 10px;
}

.ant-table-thead .overview-detail-territories-number,
.ant-table-thead .overview-detail-territories-country,
.ant-table-thead .overview-detail-territories-percentage,
.ant-table-thead .overview-detail-territories-streams,
.ant-table-thead .overview-detail-territories-track {
  font-style: var(--font-style);
  font-weight: var(--font-weight-text);
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
  letter-spacing: 0.4px;
  color: #7f8183;
}

.ant-table-tbody .overview-detail-territories-number,
.ant-table-tbody .overview-detail-territories-country,
.ant-table-tbody .overview-detail-territories-percentage,
.ant-table-tbody .overview-detail-territories-streams,
.ant-table-tbody .overview-detail-territories-track {
  font-style: var(--font-style);
  font-weight: var(--font-weight-text);
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
  letter-spacing: 0.4px;
  color: var(--header-color);
}

.ant-table-tbody .overview-detail-territories-country {
  display: flex;
  align-items: center;
}

.ant-table-tbody .overview-detail-territories-country img {
  margin-right: 5px;
}

.ant-table-thead .overview-detail-territories-asia span:nth-child(1) {
  background-color: #2ec957;
}

.ant-table-thead .overview-detail-territories-europe span:nth-child(1) {
  background-color: #f0d234;
}

.ant-table-thead .overview-detail-territories-oceania span:nth-child(1) {
  background-color: #f0a534;
}

.ant-table-thead .overview-detail-territories-northAmerica span:nth-child(1) {
  background-color: #ea3d2f;
}

.ant-table-thead .overview-detail-territories-southAmerica span:nth-child(1) {
  background-color: #6c7e9f;
}

.ant-table-thead .overview-detail-territories-africa span:nth-child(1) {
  background-color: #3399ff;
}

.ant-table-thead .overview-detail-territories-asia span:nth-child(1),
.ant-table-thead .overview-detail-territories-europe span:nth-child(1),
.ant-table-thead .overview-detail-territories-oceania span:nth-child(1),
.ant-table-thead .overview-detail-territories-northAmerica span:nth-child(1),
.ant-table-thead .overview-detail-territories-southAmerica span:nth-child(1),
.ant-table-thead .overview-detail-territories-africa span:nth-child(1) {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;
}

.statistic-container .ant-select-selector {
  border-radius: 4px !important;
}

.overview-detail-top-artist-container .recharts-legend-wrapper {
  display: flex;
  align-items: center;
}

.overview-detail-top-artist-container .recharts-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@media (max-width: 1281px) {
  .overview-detail-revenue-item-body-beside h2 {
    font-size: 18px;
  }

  .overview-detail-revenue-item-body-mid p,
  .overview-detail-revenue-item-body-side p {
    font-size: 18px;
  }

  .overview-detail-revenue-item-body-mid span,
  .overview-detail-revenue-item-body-side span {
    font-size: 12px;
  }

  .overview-detail-top-stream-container .recharts-wrapper li,
  .overview-detail-top-artist-container .recharts-wrapper li,
  .overview-region-container .recharts-wrapper li {
    font-size: 11px;
  }
}

@media (max-width: 1100px) {
  .overview-detail-top-stream-container .recharts-wrapper li,
  .overview-detail-top-artist-container .recharts-wrapper li,
  .overview-region-container .recharts-wrapper li {
    font-size: 11px;
  }

  .ant-picker-time-panel-column::after {
    content: none !important;
  }
}

@media (max-width: 980px) {
  .overview-detail-top-stream-container .recharts-wrapper li,
  .overview-detail-top-artist-container .recharts-wrapper li,
  .overview-region-container .recharts-wrapper li {
    font-size: 11px;
  }
}