.rdrStaticRange {
  border-bottom: none !important;
  margin-bottom: 5px !important;
  background: #f0f2f5 !important;
  border-radius: 4px !important;
}

.rdrDefinedRangesWrapper {
  margin-right: 20px !important;
  border-right: none !important;
}

.rdrStaticRange.rdrStaticRangeSelected {
  background-color: var(--dark-blue) !important;
}

.rdrStaticRange.rdrStaticRangeSelected span {
  color: var(--white-color) !important;
}

.rdrStaticRangeLabel {
  border-radius: 4px !important;
}

.rdrStaticRangeLabel:hover {
  background-color: var(--dark-blue) !important;
  color: var(--white-color);
  font-weight: var(--font-weight-header);
}

.rdrInputRanges {
  display: none;
}

.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  color: var(--dark-blue) !important;
}
